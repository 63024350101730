<template>
  <div class="page-main">
    <Form />
    <Tab />

    <!-- 表格操作条 -->
    <vxe-toolbar ref="refToolbar" size="mini" :loading="loading">
      <template #buttons>
        <el-button type="primary" size="mini" @click="generateXunjia">生成询价单</el-button>
        <el-button type="primary" size="mini" @click="generateCaigou">生成采购单</el-button>
      </template>
    </vxe-toolbar>

    <!-- 表格 -->
    <vxe-grid
      ref="refTable"
      size="mini"
      border
      highlight-hover-row
      show-footer
      highlight-current-row
      :loading="loading"
      :columns="tableColumn"
      :data="tableData"
      :max-height="this.$util.getViewHeight() - 150"
      :export-config="{ type: ['xlsx'], types: ['xlsx'] }"
      :keyboard-config="{ isArrow: true }"
      :footer-method="footerMethod"
      @cell-dblclick="
        ({ row }) => {
          itemAudit(row.bill_id)
        }
      "
      @keydown="tableKeydown"
    >
      <!-- slot_header -->
      <template #header_autocomplete="{ column }">
        <c-table-header
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          @change="headerFilterChange"
        />
      </template>
      <template #header_daterange="{ column }">
        <c-table-header
          type="daterange"
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          :value.sync="filterData.bill_date"
          @change="headerFilterChange"
        />
      </template>

      <!-- slot_default -->
      <template #default_cash_status="{ row }">
        <span v-if="row.cash_status === '已提交'" :style="{ color: '#999' }">
          {{ row.cash_status }}
        </span>
        <span v-if="row.cash_status === '待审核'" :style="{ color: '#66b1ff' }">
          {{ row.cash_status }}
        </span>
        <span v-if="row.cash_status === '已审核'" :style="{ color: '#67c23a' }">
          {{ row.cash_status }}
        </span>
      </template>

      <!-- slot_pager -->
      <template #pager>
        <div></div>
        <vxe-pager v-bind="pageOptions" @page-change="pageChange" />
      </template>
    </vxe-grid>
  </div>
</template>

<script>
import Form from './form'
import Tab from './tab'
export default {
  components: {
    Form,
    Tab
  },
  data() {
    return {
      loading: true,

      // filter
      filterData: {
        bill_date: [this.$util.getDate().monthDay, this.$util.getDate().today]
      },
      sortData: {
        create_date: 'desc'
      },

      // table
      headerFilterUrl: '/admin/gcvip/buyBill/searchHeard',
      tableData: [],
      tableColumn: [
        {
          fixed: 'left',
          type: 'checkbox',
          width: 50
        },
        {
          title: 'ID',
          field: 'bill_id',
          width: 80,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '采购日期',
          field: 'bill_date',
          width: 180,
          slots: { header: 'header_daterange' }
        },
        {
          title: '采购单号',
          field: 'xxx',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '销售单号',
          field: 'bill_code',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '供应商',
          field: 'xxx',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '采购类型',
          field: 'xxx',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '品名',
          field: 'xxx',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '规格',
          field: 'xxx',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '材质',
          field: 'xxx',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '数量',
          field: 'xxx',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '单价',
          field: 'xxx',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '金额',
          field: 'xxx',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '检测标准',
          field: 'xxx',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '单据状态',
          field: 'xxx',
          slots: {
            header: 'header_autocomplete',
            default: 'default_xxx'
          }
        }
      ],
      pageOptions: {
        ...this.$constant.page
      }
    }
  },
  mounted() {
    this.getListData()
    this.$nextTick(() => {
      this.$refs.refTable.connect(this.$refs.refToolbar) // 手动将表格和工具栏进行关联
    })
  },
  methods: {
    // 头部筛选更新
    headerFilterChange(obj) {
      this.filterData[obj.field] = obj.value
      this.searchListData()
    },
    // 搜索列表数据
    searchListData() {
      this.pageOptions.currentPage = 1
      this.getListData()
    },
    // 获取列表数据
    getListData() {
      this.loading = true
      this.$api({
        method: 'post',
        url: '/admin/gcvip/buyBill/search',
        data: {
          currentPage: this.pageOptions.currentPage,
          pageSize: this.pageOptions.pageSize,
          filterData: this.filterData,
          sortData: this.sortData
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            let data = res.data.page
            this.tableData = data.list
            this.pageOptions.pageSize = data.pageSize
            this.pageOptions.total = data.totalRow
            this.pageOptions.currentPage = data.pageNumber
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.pageOptions.currentPage = currentPage
      this.pageOptions.pageSize = pageSize
      this.getListData()
    },
    // 表格底部
    footerMethod({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) return '合计'
          if (
            ['sales_amount_total', 'amount_received', 'un_amount_received'].includes(column.field)
          ) {
            return this.sumNum(data, column.field)
          }
        })
      ]
    },
    sumNum(list, field) {
      let listCount = list.map((item) => {
        return item[field]
      })
      return this.$XEUtils.sum(listCount)
    },
    // 获取-当前复选行
    getCheckboxRecords() {
      return this.$refs.refTable.getCheckboxRecords()
    },
    // 表格-键盘空格
    tableKeydown(params) {
      if (params.$event.code === 'Space') {
        let row = this.$refs.refTable.getCurrentRecord()
        let isCurrentRowCheckbox = this.$refs.refTable.isCheckedByCheckboxRow(row)
        this.$refs.refTable.setCheckboxRow([row], !isCurrentRowCheckbox) // 设置行是否选中
      }
    },
    // 项-选择提示
    itemChoosePrompt() {
      this.$tool.tableChoosePrompt()
    },
    // 跳转-详情项
    jumpDetailOne() {
      let row = this.getCheckboxRecords()
      if (row.length) {
        this.jumpDetailOneAction(row.id)
      } else {
        this.itemChoosePrompt()
      }
    },
    // 项-详情项-执行
    jumpDetailOneAction(id) {
      this.$router.push(`/purchase/order/detail-one?id=${id}`)
    },
    // 跳转-详情总
    jumpDetailAll() {
      let row = this.getCheckboxRecords()
      if (row.length) {
        this.jumpDetailAllAction(row.id)
      } else {
        this.itemChoosePrompt()
      }
    },
    // 项-详情总-执行
    jumpDetailAllAction(id) {
      this.$router.push(`/purchase/order/detail-all?id=${id}`)
    },
    // 生成-询价单
    generateXunjia() {
      let row = this.getCheckboxRecords()
      if (row.length) {
        this.generateXunjiaAction(row[0].id)
      } else {
        this.itemChoosePrompt()
      }
    },
    // 生成-询价单-执行
    generateXunjiaAction(id) {
      this.$router.push(`/purchase/order/print-xunjia?id=${id}`)
    },
    // 生成-采购单
    generateCaigou() {
      let row = this.getCheckboxRecords()
      if (row.length) {
        this.generateCaigouAction(row[0].id)
      } else {
        this.itemChoosePrompt()
      }
    },
    // 生成-采购单-执行
    generateCaigouAction(id) {
      this.$router.push(`/purchase/order/print-caigou?id=${id}`)
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
